import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./theme";

// Styles
import "./App.css";

// Scenes
import Login from "./scenes/login/login";
import Dashboard from "./scenes/dashboard/dashboard";

function App() {
  const token = localStorage.getItem("empToken");

  return (
    <ThemeProvider theme={appTheme}>
      <div className="appWrapper">
        <div className="app">{!token ? <Login /> : <Dashboard />}</div>
      </div>
    </ThemeProvider>
  );
}

export default App;
