import React from "react";

// MUI Components
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

// Styles
import "./deletionModal.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

const DeletionModal = (props: any) => {
  return (
    <div className="deletionModalBackground">
      <div className="deletionModalContainer">
        <div className="deletionModalHeader">
          <div className="deletionModalHeaderLeft">
            <h2>Löschen</h2>
          </div>
          <div className="deletionModalHeaderRight">
            <IconButton onClick={() => props.closeModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="deletionModalBody">
          <p>
            Dieser Löschvorgang kann nicht rückgängig gemacht werden!
            <br />
            <br />
            Sicher, dass der Auftrag gelöscht werden soll?
          </p>
        </div>
        <div className="deletionModalFooter">
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => props.deleteItems()}
          >
            Löschen
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeletionModal;
