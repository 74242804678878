import { createTheme } from "@mui/material/styles";
import { deDE as dataGridDeDE } from "@mui/x-data-grid";
import { deDE as coreDeDE } from "@mui/material/locale";
import { deDE } from "@mui/x-date-pickers/locales";

export const appTheme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#0066FF",
      },
      secondary: {
        main: "#cccfd4",
      },
    },
    typography: {
      button: {
        textTransform: "none",
        fontSize: 16,
      },
    },
  },
  deDE,
  dataGridDeDE,
  coreDeDE
);
