import React, { useState } from "react";
import logo1 from "../../Lechner_Logo.png";
import logo2 from "../../EMP_Logov2.png";

// Styles
import "./login.css";

// MUI Components
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IconButton, InputAdornment } from "@mui/material";

// Custom Components
import DividerS from "../../components/common/dividers/dividerS";

// Icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailHelpertext, setEmailHelpertext] = useState("");
  const [passwordHelpertext, setPasswordHelpertext] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signin = () => {
    if (email === "") {
      setEmailHelpertext("Bitte gib eine gültige E-Mail Adresse ein");
    } else {
      setEmailHelpertext("");
    }

    if (password === "") {
      setPasswordHelpertext("Bitte gib ein Passwort ein");
    } else {
      setPasswordHelpertext("");
    }

    if (email === "" || password === "") {
      return;
    }

    fetch(`/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message === "UserNotFound") {
          setEmailHelpertext(
            "Es wurde kein Nutzer mit der E-Mail Adresse gefunden"
          );
        } else if (data.message === "IncorrectPassword") {
          setPasswordHelpertext("Passwort inkorrekt");
        } else if (data.message === "SuccessfulLogin") {
          localStorage.setItem("empToken", data.token);
          localStorage.setItem("empUserId", data.user.id);
          window.location.href = "/open-orders";
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div
      className="signinMainContainer"
      onKeyDown={(event) => event.key === "Enter" && signin()}
      tabIndex={0}
    >
      <div className="signinCenterContainer">
        <div className="signinMainContainerRightHeader">
          <div className="signinMainContainerRightHeaderLogo">
            <img src={logo2} alt="logo" />
            <p>EMP</p>
          </div>
          <h2>Anmelden</h2>
        </div>
        <div className="signinMainContainerRightBody">
          <TextField
            id="outlined-basic"
            label="E-Mail Adresse"
            variant="outlined"
            className="loginInput"
            type="email"
            value={email}
            onChange={(value) => setEmail(value.target.value)}
            required
            error={emailHelpertext === "" ? false : true}
            helperText={emailHelpertext}
          />
          <DividerS />
          <TextField
            id="outlined-basic"
            label="Passwort"
            variant="outlined"
            className="loginInput"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(value) => setPassword(value.target.value)}
            required
            error={passwordHelpertext === "" ? false : true}
            helperText={passwordHelpertext}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    style={{ color: "#a5a5a5" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <DividerS />
          <Button variant="outlined" onClick={() => signin()}>
            Anmelden
          </Button>
          {/* <div className="forgotPassword">Passwort vergessen?</div>
            <DividerS /> */}
          <DividerS />
        </div>
      </div>
    </div>
  );
};

export default Login;
