import React from "react";

// MUI Components
import IconButton from "@mui/material/IconButton";

// Styles
import "./supportModal.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";

const SupportModal = (props: any) => {
  return (
    <div className="supportModalBackground">
      <div className="supportModalContainer">
        <div className="supportModalHeader">
          <div className="supportModalHeaderLeft">
            <h2>Hilfe</h2>
          </div>
          <div className="supportModalHeaderRight">
            <IconButton onClick={() => props.closeModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="supportModalBody">
          <div className="supportModalBodyTop">
            {/*<h2>Häufig gestellte Fragen</h2>
            <div className="questionContainer">
              <div className="questionText">
                <p>Frage: Frage 1</p>
              </div>
              <div className="questionAnswer">
                <p>Antwort: Antwort 1</p>
              </div>
            </div>
            <div className="questionContainer">
              <div className="questionText">
                <p>Frage: Frage 2</p>
              </div>
              <div className="questionAnswer">
                <p>Antwort: Antwort 2</p>
              </div>
            </div>*/}
            <p>
              Wende dich bei Fragen oder Problemen bitte an deinen zuständigen
              EMP-Tool Ansprechpartner.
            </p>
          </div>
          <div className="supportModalBodyBottom"></div>
        </div>
      </div>
    </div>
  );
};

export default SupportModal;
