import React, { useState, useEffect } from "react";
import { Dayjs } from "dayjs";

// MUI Components
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Components
import toast, { Toaster } from "react-hot-toast";

// Styles
import "./masterDataModal.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const MasterDataModal = (props: any) => {
  const [ident, setIdent] = useState(
    //props.item.ident === "" ? "" :
    props.item.ident
  );
  const [articleId, setArticleId] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "articles" ? props.item.articleId : ""
  );
  const [articleNumber, setArticleNumber] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "articles" ? props.item.articleNumber : ""
  );
  const [articleName, setArticleName] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "articles" ? props.item.name : ""
  );
  const [customerId, setCustomerId] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" ? props.item.id : ""
  );
  const [customerName, setCustomerName] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" ? props.item.name : ""
  );
  const [carrierId, setCarrierId] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "carriers" ? props.item.id : ""
  );
  const [carrierName, setCarrierName] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "carriers" ? props.item.name : ""
  );
  const [addressId, setAddressId] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.addressId
      : ""
  );
  const [addressLine1, setAddressLine1] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.addressLine1
      : ""
  );
  const [city, setCity] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.city
      : ""
  );
  const [postcode, setPostcode] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.postcode
      : ""
  );
  const [countryId, setCountryId] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.countryId
      : ""
  );
  const [countryName, setCountryName] = useState(
    //props.item.ident === "" ? "" :
    props.scene === "customers" || props.scene === "carriers"
      ? props.item.countryName
      : ""
  );

  const [countries, setCountries] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [carriers, setCarriers] = useState([]);

  const errorToast = (message: any) => toast.error(message);

  const [intRegex, setIntRegex] = useState(/^[0-9]+$/);

  const [customerNameHelpertext, setCustomerNameHelpertext] = useState("");
  const [carrierNameHelpertext, setCarrierNameHelpertext] = useState("");
  const [articleNameHelpertext, setArticleNameHelpertext] = useState("");
  const [articleNumberHelpertext, setArticleNumberHelpertext] = useState("");

  const [addressLine1Helpertext, setAddressLine1Helpertext] = useState("");
  const [cityHelpertext, setCityHelpertext] = useState("");
  const [postcodeHelpertext, setPostcodeHelpertext] = useState("");
  const [countryNameHelpertext, setCountryNameHelpertext] = useState("");

  const getCountries = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/countries`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCountries(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (props.scene === "customers" || props.scene === "carriers") {
      getCountries();
    }
  }, [props.scene]);

  const getAddresses = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/addresses`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAddresses(data);
      })
      .catch((err) => console.error(err));
  };

  const saveArticle = () => {
    if (articleNumber === "") {
      setArticleNumberHelpertext("Artikelnummer darf nicht leer sein");

      errorToast("Ungültige Artikelnummer");
    } else {
      setArticleNumberHelpertext("");
    }

    if (articleName === "") {
      setArticleNameHelpertext("Artikelname darf nicht leer sein");

      errorToast("Ungültiger Artikelname");
    } else {
      setArticleNameHelpertext("");
    }

    if (articleName === "" || articleNumber === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(`/api/articles`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        articleNumber: articleNumber,
        name: articleName,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const updateArticle = () => {
    if (articleNumber === "") {
      setArticleNumberHelpertext("Artikelnummer darf nicht leer sein");

      errorToast("Ungültige Artikelnummer");
    } else {
      setArticleNumberHelpertext("");
    }

    if (articleName === "") {
      setArticleNameHelpertext("Artikelname darf nicht leer sein");

      errorToast("Ungültiger Artikelname");
    } else {
      setArticleNameHelpertext("");
    }

    if (articleName === "" || articleNumber === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(
      `/api/articles/` + props.item.id,
      {
        method: "PUT",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          articleNumber: articleNumber,
          name: articleName,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const saveCustomer = () => {
    if (customerName === "") {
      setCustomerNameHelpertext("Adressatenname darf nicht leer sein");

      errorToast("Ungültiger Adressat");
    } else {
      setCustomerNameHelpertext("");
    }

    if (addressLine1 === "" || !addressLine1) {
      setAddressLine1Helpertext("Ungültige Straße");

      errorToast("Ungültige Straße");
    }

    if (city === "" || !city) {
      setCityHelpertext("Ungültige Stadt");

      errorToast("Ungültige Stadt");
    }

    if (postcode === "" || !postcode) {
      setPostcodeHelpertext("Ungültige Postleitzahl");

      errorToast("Ungültige Postleitzahl");
    }

    if (countryId === "" || !countryId) {
      setCountryNameHelpertext("Ungültiges Land");

      errorToast("Ungültiges Land");
    }

    if (
      addressLine1 === "" ||
      city === "" ||
      postcode === "" ||
      countryId === "" ||
      !addressLine1 ||
      !city ||
      !postcode ||
      !countryId
    ) {
      return;
    } else {
      setAddressLine1Helpertext("");
      setCityHelpertext("");
      setPostcodeHelpertext("");
      setCountryNameHelpertext("");
    }

    if (customerName === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(`/api/customers`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: customerName,
        addressLine1: addressLine1,
        city: city,
        postcode: postcode,
        countryId: countryId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const updateCustomer = () => {
    if (customerName === "") {
      setCustomerNameHelpertext("Adressatenname darf nicht leer sein");

      errorToast("Ungültiger Adressat");
    } else {
      setCustomerNameHelpertext("");
    }

    if (addressLine1 === "" || !addressLine1) {
      setAddressLine1Helpertext("Ungültige Straße");

      errorToast("Ungültige Straße");
    }

    if (city === "" || !city) {
      setCityHelpertext("Ungültige Stadt");

      errorToast("Ungültige Stadt");
    }

    if (postcode === "" || !postcode) {
      setPostcodeHelpertext("Ungültige Postleitzahl");

      errorToast("Ungültige Postleitzahl");
    }

    if (countryId === "" || !countryId) {
      setCountryNameHelpertext("Ungültiges Land");

      errorToast("Ungültiges Land");
    }

    if (
      addressLine1 === "" ||
      city === "" ||
      postcode === "" ||
      countryId === "" ||
      !addressLine1 ||
      !city ||
      !postcode ||
      !countryId
    ) {
      return;
    } else {
      setAddressLine1Helpertext("");
      setCityHelpertext("");
      setPostcodeHelpertext("");
      setCountryNameHelpertext("");
    }

    if (customerName === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(
      `/api/customers/` + props.item.id,
      {
        method: "PUT",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: customerName,
          addressLine1: addressLine1,
          city: city,
          postcode: postcode,
          countryId: countryId,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const saveCarrier = () => {
    if (carrierName === "") {
      setCarrierNameHelpertext("Speditionsname darf nicht leer sein");

      errorToast("Ungültiger Spediteur");
    } else {
      setCarrierNameHelpertext("");
    }

    if (addressLine1 === "" || !addressLine1) {
      setAddressLine1Helpertext("Ungültige Straße");

      errorToast("Ungültige Straße");
    }

    if (city === "" || !city) {
      setCityHelpertext("Ungültige Stadt");

      errorToast("Ungültige Stadt");
    }

    if (postcode === "" || !postcode) {
      setPostcodeHelpertext("Ungültige Postleitzahl");

      errorToast("Ungültige Postleitzahl");
    }

    if (countryId === "" || !countryId) {
      setCountryNameHelpertext("Ungültiges Land");

      errorToast("Ungültiges Land");
    }

    if (
      addressLine1 === "" ||
      city === "" ||
      postcode === "" ||
      countryId === "" ||
      !addressLine1 ||
      !city ||
      !postcode ||
      !countryId
    ) {
      return;
    } else {
      setAddressLine1Helpertext("");
      setCityHelpertext("");
      setPostcodeHelpertext("");
      setCountryNameHelpertext("");
    }

    if (carrierName === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(`/api/carriers`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        carrierName: carrierName,
        addressLine1: addressLine1,
        city: city,
        postcode: postcode,
        countryId: countryId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const updateCarrier = () => {
    if (carrierName === "") {
      setCarrierNameHelpertext("Speditionsname darf nicht leer sein");

      errorToast("Ungültiger Spediteur");
    } else {
      setCarrierNameHelpertext("");
    }

    if (addressLine1 === "" || !addressLine1) {
      setAddressLine1Helpertext("Ungültige Straße");

      errorToast("Ungültige Straße");
    }

    if (city === "" || !city) {
      setCityHelpertext("Ungültige Stadt");

      errorToast("Ungültige Stadt");
    }

    if (postcode === "" || !postcode) {
      setPostcodeHelpertext("Ungültige Postleitzahl");

      errorToast("Ungültige Postleitzahl");
    }

    if (countryId === "" || !countryId) {
      setCountryNameHelpertext("Ungültiges Land");

      errorToast("Ungültiges Land");
    }

    if (
      addressLine1 === "" ||
      city === "" ||
      postcode === "" ||
      countryId === "" ||
      !addressLine1 ||
      !city ||
      !postcode ||
      !countryId
    ) {
      return;
    } else {
      setAddressLine1Helpertext("");
      setCityHelpertext("");
      setPostcodeHelpertext("");
      setCountryNameHelpertext("");
    }

    if (carrierName === "") {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(
      `/api/carriers/` + props.item.id,
      {
        method: "PUT",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          carrierName: carrierName,
          addressLine1: addressLine1,
          city: city,
          postcode: postcode,
          countryId: countryId,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getItems();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  return (
    <div className="masterDataModalBackground">
      <div className="masterDataModalContainer">
        <div className="masterDataModalHeader">
          {props.scene === "customers" ? (
            <h2>
              {props.item.id === "" ? "Neuer Adressat" : "Adressat bearbeiten"}
            </h2>
          ) : props.scene === "articles" ? (
            <h2>
              {props.item.id === "" ? "Neuer Artikel" : "Artikel bearbeiten"}
            </h2>
          ) : props.scene === "addresses" ? (
            <h2>
              {props.item.id === "" ? "Neue Adresse" : "Adresse bearbeiten"}
            </h2>
          ) : (
            <h2>
              {props.item.id === "" ? "Neue Spedition" : "Spedition bearbeiten"}
            </h2>
          )}
          <IconButton onClick={() => props.closeModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="masterDataModalBody">
          {props.scene === "customers" && (
            <TextField
              id="outlined-select-customer"
              label="Name"
              value={customerName}
              //onFocus={() => getCustomers()}
              onChange={(value) => setCustomerName(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={customerNameHelpertext === "" ? false : true}
              helperText={customerNameHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            ></TextField>
          )}
          {props.scene === "carriers" && (
            <TextField
              id="outlined-select-carrier"
              label="Name"
              value={carrierName}
              //onFocus={() => getCarriers()}
              onChange={(value) => setCarrierName(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={carrierNameHelpertext === "" ? false : true}
              helperText={carrierNameHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            ></TextField>
          )}
          {props.scene === "articles" && (
            <TextField
              id="outlined-basic"
              label="Artikelnummer"
              variant="outlined"
              value={articleNumber}
              onChange={(value) => setArticleNumber(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={articleNumberHelpertext === "" ? false : true}
              helperText={articleNumberHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            />
          )}
          {props.scene === "articles" && (
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={articleName}
              onChange={(value) => setArticleName(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={articleNameHelpertext === "" ? false : true}
              helperText={articleNameHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            />
          )}
          {(props.scene === "customers" ||
            props.scene === "addresses" ||
            props.scene === "carriers") && (
            <TextField
              id="outlined-basic"
              label="Straße"
              variant="outlined"
              value={addressLine1}
              onChange={(value) => setAddressLine1(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={addressLine1Helpertext === "" ? false : true}
              helperText={addressLine1Helpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            />
          )}
          {(props.scene === "customers" ||
            props.scene === "addresses" ||
            props.scene === "carriers") && (
            <TextField
              id="outlined-basic"
              label="Stadt"
              variant="outlined"
              value={city}
              onChange={(value) => setCity(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={cityHelpertext === "" ? false : true}
              helperText={cityHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            />
          )}
          {(props.scene === "customers" ||
            props.scene === "addresses" ||
            props.scene === "carriers") && (
            <TextField
              id="outlined-basic"
              label="Postleitzahl"
              variant="outlined"
              value={postcode}
              onChange={(value) => setPostcode(value.target.value)}
              sx={{ marginBottom: "25px", width: "100%" }}
              required
              error={postcodeHelpertext === "" ? false : true}
              helperText={postcodeHelpertext}
              size="small"
              InputProps={{
                sx: { height: 40 },
              }}
            />
          )}
          {(props.scene === "customers" ||
            props.scene === "addresses" ||
            props.scene === "carriers") && (
            <Autocomplete
              disablePortal
              id="outlined-basic"
              options={countries.map(
                (option: { nameDE: string }) => option.nameDE
              )}
              onChange={(event, newName) => {
                const selectedItem: any = countries.find(
                  (item: any) => item.nameDE === newName
                );
                if (selectedItem) {
                  setCountryId(selectedItem.id);
                  setCountryName(selectedItem.nameDE);
                } else {
                  setCountryId("");
                  setCountryName("");
                }
              }}
              value={countryName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="outlined-select-country"
                  label="Land"
                  //onFocus={() => getCustomers()}
                  sx={{ marginBottom: "25px", width: "100%" }}
                  required
                  error={countryNameHelpertext === "" ? false : true}
                  helperText={countryNameHelpertext}
                  size="small"
                />
              )}
            />
          )}
        </div>
        <div className="masterDataModalFooter">
          <Button
            className="masterDataModalFooterButton"
            variant="contained"
            //startIcon={<SaveIcon />}
            sx={{ marginRight: "3%", height: 33 }}
            onClick={
              props.scene === "customers"
                ? props.item.ident === ""
                  ? () => saveCustomer()
                  : () => updateCustomer()
                : props.scene === "articles"
                ? props.item.ident === ""
                  ? () => saveArticle()
                  : () => updateArticle()
                : props.item.ident === ""
                ? () => saveCarrier()
                : () => updateCarrier()
            }
          >
            {props.item.ident === "" ? "Speichern" : "Speichern"}
          </Button>
          <Button
            className="masterDataModalFooterButton"
            variant="outlined"
            sx={{ height: 33 }}
            onClick={() => props.closeModal(false)}
          >
            Abbruch
          </Button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default MasterDataModal;
