import React, { useState, useEffect, useRef } from "react";

// MUI Components
import IconButton from "@mui/material/IconButton";
import { Chip } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Custom Components
import MasterDataModal from "../masterDataModal/masterDataModal";
import toast, { Toaster } from "react-hot-toast";

// Styles
import "./orderModal.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const OrderModal = (props: any) => {
  const [ident, setIdent] = useState(
    props.order.ident === "" ? "" : props.order.ident
  );
  const [nextIdent, setNextIdent] = useState(null);
  const [orderState, setOrderState] = useState(
    props.order.ident === "" ? "Neu" : props.order.orderState
  );
  const [lieferscheinNummer, setLieferscheinNummer] = useState(
    props.order.ident === "" ? "" : props.order.lieferscheinNummer
  );
  const [articleNumber, setArticleNumber] = useState(
    props.order.ident === "" ? "" : props.order.article.articleNumber
  );
  const [articleName, setArticleName] = useState(
    props.order.ident === "" ? "" : props.order.article.name
  );
  const [articleId, setArticleId] = useState(
    props.order.ident === "" ? null : props.order.article.id
  );
  const [amount, setAmount] = useState(
    props.order.ident === "" ? "" : props.order.amount
  );
  const [senderId, setSenderId] = useState(
    props.order.ident === "" ? "" : props.order.sender.id
  );
  const [senderName, setSenderName] = useState(
    props.order.ident === "" ? "" : props.order.sender.name
  );
  const [recipientId, setRecipientId] = useState(
    props.order.ident === "" ? "" : props.order.recipient.id
  );
  const [recipientName, setRecipientName] = useState(
    props.order.ident === "" ? "" : props.order.recipient.name
  );
  const [carrierId, setCarrierId] = useState(
    props.order.ident === ""
      ? ""
      : props.order.carrier === null
      ? ""
      : props.order.carrier.id
  );
  const [carrierName, setCarrierName] = useState(
    props.order.ident === ""
      ? ""
      : props.order.carrier === null
      ? ""
      : props.order.carrier.name
  );
  const [sdBeleg, setSdBeleg] = useState(
    props.order.ident === "" ? "" : props.order.sdBeleg
  );
  const [pspElement, setPspElement] = useState(
    props.order.ident === "" ? "" : props.order.pspElement
  );
  const [versorgungsNummer, setVersorgungsNummer] = useState(
    props.order.ident === "" ? "" : props.order.versorgungsNummer
  );

  const [handlingUnitsData, setHandlingUnitsData] = useState(
    props.order.ident === "" ? [] : props.order.handlingUnits
  );
  const [handlingUnits, setHandlingUnits] = useState([]);
  const [orderFiles, setOrderFiles] = useState(
    props.order.ident === "" ? [] : props.order.orderFiles
  );
  const [weight, setWeight] = useState(
    props.order.ident === "" ? 0 : props.order.weightBrutto.toFixed(2)
  );
  const [packagingStage, setPackagingStage] = useState(
    props.order.ident === "" ? "" : props.order.packagingStage
  );
  const [specifics, setSpecifics] = useState(
    props.order.ident === "" ? "" : props.order.specifics
  );
  const [orderDate, setOrderDate] = useState(
    props.order.ident !== "" && props.order.orderDate !== null
      ? dayjs(props.order.orderDate)
      : null
  );
  const [finishDate, setFinishDate] = useState(
    props.order.ident !== "" && props.order.finishDate !== null
      ? dayjs(props.order.finishDate)
      : null
  );
  const [pickUpDate, setPickUpDate] = useState(
    props.order.ident !== "" && props.order.pickUpDate !== null
      ? dayjs(props.order.pickUpDate)
      : null
  );
  const [idGoodsIssued, setIdGoodsIssued] = useState(
    props.order.ident === "" ? "" : props.order.idGoodsIssued
  );
  const [isPriority, setIsPriority] = useState(
    props.order.ident === "" ? false : props.order.isPriority
  );

  const [packagingStages, setPackagingStages] = useState([
    { value: "H", displayName: "H" },
    { value: "C", displayName: "C" },
    { value: "B", displayName: "B" },
  ]);

  const [specificsOptions, setSpecificsOptions] = useState([
    { value: "ADR", displayName: "ADR" },
    { value: "WvB", displayName: "WvB" },
    { value: "IATA", displayName: "IATA" },
    { value: "IMDG", displayName: "IMDG" },
  ]);

  const [orderStates, setOrderStates] = useState([
    { value: "Neu", displayName: "Neu" },
    { value: "In Bearbeitung", displayName: "In Bearbeitung" },
    { value: "Rückmeldung Kunde", displayName: "Rückmeldung Kunde" },
    { value: "Zur Abholung bereit", displayName: "Zur Abholung bereit" },
    { value: "Abgeholt", displayName: "Abgeholt" },
    { value: "In Abrechnung", displayName: "In Abrechnung" },
    { value: "Abgerechnet", displayName: "Abgerechnet" },
    { value: "Verladestopp", displayName: "Verladestopp" },
  ]);

  const [countries, setCountries] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [articles, setArticles] = useState([]);

  const [isOcrScanning, setIsOcrScanning] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedPDF, setUploadedPDF] = useState("");

  const ocrInputStyles = {
    marginBottom: "25px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(220, 82, 13)",
      },
    },
  };

  const [ocrLieferscheinnummer, setOcrLieferscheinnummer] = useState(false);
  const [ocrOrderDate, setOcrOrderDate] = useState(false);
  const [ocrSender, setOcrSender] = useState(false);
  const [ocrRecipient, setOcrRecipient] = useState(false);
  const [ocrCarrier, setOcrCarrier] = useState(false);
  const [ocrSdBeleg, setOcrSdBeleg] = useState(false);
  const [ocrPspElement, setOcrPspElement] = useState(false);
  const [ocrArticle, setOcrArticle] = useState(false);
  const [ocrAmount, setOcrAmount] = useState(false);
  const [ocrPackagingStage, setOcrPackagingStage] = useState(false);
  const [ocrVersorgungsNummer, setOcrVersorgungsNummer] = useState(false);

  const errorToast = (message: any) => toast.error(message);
  const successToast = (message: any) => toast.success(message);

  const [intRegex, setIntRegex] = useState(/^[0-9]+$/);

  const [lieferscheinnummerHelpertext, setLieferscheinNummerHelpertext] =
    useState("");
  const [senderHelpertext, setSenderHelpertext] = useState("");
  const [recipientHelpertext, setRecipientHelpertext] = useState("");
  const [orderDateHelpertext, setOrderDateHelpertext] = useState("");
  const [finishDateHelpertext, setFinishDateHelpertext] = useState("");
  const [pickUpDateHelpertext, setPickUpDateHelpertext] = useState("");
  const [articleHelpertext, setArticleHelpertext] = useState("");
  const [sdBelegHelpertext, setSdBelegtHelpertext] = useState("");
  const [amountHelpertext, setAmountHelpertext] = useState("");

  const getCustomers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/customers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCustomers(data);
      })
      .catch((err) => console.error(err));
  };

  const getCarriers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/carriers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCarriers(data);
      })
      .catch((err) => console.error(err));
  };

  const getArticles = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/articles`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setArticles(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getCustomers();
    getCarriers();
    getArticles();
  }, []);

  const saveOrder = () => {
    if (lieferscheinNummer === "") {
      setLieferscheinNummerHelpertext("Lieferscheinnr. darf nicht leer sein");

      errorToast("Ungültige Lieferscheinnr.");
    } else {
      setLieferscheinNummerHelpertext("");
    }

    if (!orderDate || dayjs(orderDate).isValid() === false) {
      setOrderDateHelpertext("Auftragsdatum darf nicht leer sein");

      errorToast("Ungültiges Auftragsdatum");
    } else {
      setOrderDateHelpertext("");
    }

    if (finishDate !== null && dayjs(finishDate).isValid() === false) {
      setFinishDateHelpertext("Auftrag Abschluss ist ungültig");

      errorToast("Ungültiger Auftrag Abschluss");
    } else {
      setFinishDateHelpertext("");
    }

    if (pickUpDate !== null && dayjs(pickUpDate).isValid() === false) {
      setPickUpDateHelpertext("Abgeholt am ist ungültig");

      errorToast("Ungültiges Abgeholt am Datum");
    } else {
      setPickUpDateHelpertext("");
    }

    if (!senderId || !senderName) {
      setSenderHelpertext("Absender darf nicht leer sein");

      errorToast("Ungültiger Absender");
    } else {
      setSenderHelpertext("");
    }

    if (!recipientId || !recipientName) {
      setRecipientHelpertext("Empfänger darf nicht leer sein");

      errorToast("Ungültiger Empfänger");
    } else {
      setRecipientHelpertext("");
    }

    if (!articleId) {
      setArticleHelpertext("Artikel darf nicht leer sein");

      errorToast("Ungültiger Artikel");
    } else {
      setArticleHelpertext("");
    }

    if (amount === "") {
      setAmountHelpertext("Anzahl darf nicht leer sein");

      errorToast("Ungültige Anzahl");
    } else {
      setAmountHelpertext("");
    }

    if (
      lieferscheinNummer === "" ||
      !orderDate ||
      dayjs(orderDate).isValid() === false ||
      senderId === "" ||
      recipientId === "" ||
      articleId === "" ||
      amount === ""
    ) {
      return;
    }

    let isSdBelegValid = intRegex.exec(sdBeleg);
    let isAmountValid = intRegex.exec(amount);

    if (!isSdBelegValid && sdBeleg !== null && sdBeleg !== "") {
      setSdBelegtHelpertext("Ungültiger SD-Beleg! Nur Nummern sind erlaubt");

      errorToast("Ungültiger SD-Beleg");
    } else {
      setSdBelegtHelpertext("");
    }

    if (!isAmountValid) {
      setAmountHelpertext("Ungültige Anzahl");

      errorToast("Ungültige Anzahl");
    } else {
      setAmountHelpertext("");
    }

    if (
      (!isSdBelegValid && sdBeleg !== null && sdBeleg !== "") ||
      !isAmountValid
    ) {
      return;
    }

    const token = localStorage.getItem("empToken");

    let finalOrderDate: any = orderDate;
    let finalFinishDate: any = finishDate;
    let finalPickUpDate: any = pickUpDate;

    if (finalOrderDate !== null) {
      finalOrderDate = dayjs(orderDate).format("YYYY-MM-DD");
    }

    if (finalFinishDate !== null) {
      finalFinishDate = dayjs(finishDate).format("YYYY-MM-DD");
    }

    if (finalPickUpDate !== null) {
      finalPickUpDate = dayjs(pickUpDate).format("YYYY-MM-DD");
    }

    fetch(`/api/orders`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sdBeleg: sdBeleg === "" ? null : sdBeleg,
        pspElement: pspElement,
        versorgungsNummer: versorgungsNummer,
        lieferscheinNummer: lieferscheinNummer,
        amount: amount,
        handlingUnits: handlingUnitsData,
        weightBrutto: weight,
        packagingStage: packagingStage,
        specifics: specifics,
        orderState: orderState,
        orderDate: finalOrderDate,
        finishDate: finalFinishDate,
        pickUpDate: finalPickUpDate,
        idGoodsIssued: idGoodsIssued,
        senderId: senderId,
        recipientId: recipientId,
        carrierId: carrierId === "" ? null : carrierId,
        articleId: articleId,
        isPriority: isPriority,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message === "Existing order") {
          toast.error("Lieferscheinnummer existiert bereits");

          return;
        }

        props.downloadOrderCoverPdf(data.data);
        props.downloadLaborNotePdf(data.data);
        props.getOrders();
        props.closeModal(false);
        props.toastListener("success");
        if (data.identChanged) {
          props.toastListener("identChanged", data.data.ident);
        }
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  const updateOrder = () => {
    if (lieferscheinNummer === "") {
      setLieferscheinNummerHelpertext("Lieferscheinnr. darf nicht leer sein");

      errorToast("Ungültige Lieferscheinnr.");
    } else {
      setLieferscheinNummerHelpertext("");
    }

    if (!orderDate || dayjs(orderDate).isValid() === false) {
      setOrderDateHelpertext("Auftragsdatum darf nicht leer sein");

      errorToast("Ungültiges Auftragsdatum");
    } else {
      setOrderDateHelpertext("");
    }

    if (finishDate !== null && dayjs(finishDate).isValid() === false) {
      setFinishDateHelpertext("Auftrag Abschluss ist ungültig");

      errorToast("Ungültiger Auftrag Abschluss");
    } else {
      setFinishDateHelpertext("");
    }

    if (pickUpDate !== null && dayjs(pickUpDate).isValid() === false) {
      setPickUpDateHelpertext("Abgeholt am ist ungültig");

      errorToast("Ungültiges Abgeholt am Datum");
    } else {
      setPickUpDateHelpertext("");
    }

    if (!senderId) {
      setSenderHelpertext("Absender darf nicht leer sein");

      errorToast("Ungültiger Absender");
    } else {
      setSenderHelpertext("");
    }

    if (!recipientId) {
      setRecipientHelpertext("Empfänger darf nicht leer sein");

      errorToast("Ungültiger Empfänger");
    } else {
      setRecipientHelpertext("");
    }

    if (!articleId) {
      setArticleHelpertext("Artikel darf nicht leer sein");

      errorToast("Ungültiger Artikel");
    } else {
      setArticleHelpertext("");
    }

    if (amount === "") {
      setAmountHelpertext("Anzahl darf nicht leer sein");

      errorToast("Ungültige Anzahl");
    } else {
      setAmountHelpertext("");
    }

    if (
      lieferscheinNummer === "" ||
      !orderDate ||
      dayjs(orderDate).isValid() === false ||
      senderId === "" ||
      recipientId === "" ||
      articleId === "" ||
      amount === ""
    ) {
      return;
    }

    let isSdBelegValid = intRegex.exec(sdBeleg);
    let isAmountValid = intRegex.exec(amount);

    if (!isSdBelegValid && sdBeleg !== null && sdBeleg !== "") {
      setSdBelegtHelpertext("Ungültiger SD-Beleg");

      errorToast("Ungültiger SD-Beleg");
    } else {
      setSdBelegtHelpertext("");
    }

    if (!isAmountValid) {
      setAmountHelpertext("Ungültige Anzahl");

      errorToast("Ungültige Anzahl");
    } else {
      setAmountHelpertext("");
    }

    if (
      (!isSdBelegValid && sdBeleg !== null && sdBeleg !== "") ||
      !isAmountValid
    ) {
      return;
    }

    const token = localStorage.getItem("empToken");

    fetch(`/api/orders/` + props.order.id, {
      method: "PUT",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sdBeleg: sdBeleg === "" ? null : sdBeleg,
        pspElement: pspElement,
        versorgungsNummer: versorgungsNummer,
        lieferscheinNummer: lieferscheinNummer,
        amount: amount,
        handlingUnits: handlingUnitsData,
        weightBrutto: weight,
        packagingStage: packagingStage,
        specifics: specifics,
        orderState: orderState,
        orderDate: orderDate,
        finishDate: finishDate,
        pickUpDate: pickUpDate,
        idGoodsIssued: idGoodsIssued,
        senderId: senderId,
        recipientId: recipientId,
        carrierId: carrierId === "" ? null : carrierId,
        articleId: articleId,
        isPriority: isPriority,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        props.getOrders();
        props.closeModal(false);
        props.toastListener("success");
      })
      .catch((err) => {
        console.error(err);
        props.toastListener("error");
      });
  };

  useEffect(() => {
    if (props.order.ident === "") {
      getNextIdent();
    }
  }, [props.order.ident]); // Only run when props.order.ident changes

  const getNextIdent = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/orders/actions/new-id`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setNextIdent(data.nextIdent); // Store the fetched ident in state
      })
      .catch((err) => console.error(err));
  };

  const handleOCRFileUpload = (event: any) => {
    setIsOcrScanning(true);
    let uploadToast = toast.loading("Lieferschein einlesen...");
    const selectedFile = event.target.files[0];

    const formData = new FormData();
    formData.append("file", selectedFile);

    const token = localStorage.getItem("empToken");

    fetch(`/api/orders/ocr-upload`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        // Note: Content-Type is automatically set to multipart/form-data when using FormData
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setUploadedPDF(selectedFile.name);
        setIsOcrScanning(false);
        toast.success(data.message, {
          id: uploadToast,
        });

        if (data.data.lieferscheinNummer) {
          setLieferscheinNummer(data.data.lieferscheinNummer);
          setOcrLieferscheinnummer(true);
        }

        if (data.data.orderDate) {
          setOrderDate(dayjs(data.data.orderDate));
          setOcrOrderDate(true);
        }

        if (data.data.senderId && data.data.senderName) {
          setSenderId(data.data.senderId);
          setSenderName(data.data.senderName);
          setOcrSender(true);
        }

        if (data.data.recipientId && data.data.recipientName) {
          setRecipientId(data.data.recipientId);
          setRecipientName(data.data.recipientName);
          setOcrRecipient(true);
        }

        if (data.data.carrierId && data.data.carrierName) {
          setCarrierId(data.data.carrierId);
          setCarrierName(data.data.carrierName);
          setOcrCarrier(true);
        }

        if (data.data.sdBeleg) {
          setSdBeleg(data.data.sdBeleg);
          setOcrSdBeleg(true);
        }

        if (data.data.pspElement) {
          setPspElement(data.data.pspElement);
          setOcrPspElement(true);
        }

        if (
          data.data.articleId &&
          data.data.articleName &&
          data.data.articleNumber
        ) {
          setArticleId(data.data.articleId);
          setArticleName(data.data.articleName);
          setArticleNumber(data.data.articleNumber);
          setOcrArticle(true);
        }

        if (data.data.amount) {
          setAmount(data.data.amount);
          setOcrAmount(true);
        }

        if (data.data.packagingStage) {
          setPackagingStage(data.data.packagingStage);
          setOcrPackagingStage(true);
        }

        if (data.data.versorgungsNummer) {
          setVersorgungsNummer(data.data.versorgungsNummer);
          setOcrVersorgungsNummer(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const removeUploadedOCRPdf = () => {
    if (ocrLieferscheinnummer) {
      setLieferscheinNummer("");
      setOcrLieferscheinnummer(false);
    }

    if (ocrOrderDate) {
      setOrderDate(null);
      setOcrOrderDate(false);
    }

    if (ocrSender) {
      setSenderId("");
      setSenderName("");
      setOcrSender(false);
    }

    if (ocrRecipient) {
      setRecipientId("");
      setRecipientName("");
      setOcrRecipient(false);
    }

    if (ocrCarrier) {
      setCarrierId("");
      setCarrierName("");
      setOcrCarrier(false);
    }

    if (ocrSdBeleg) {
      setSdBeleg("");
      setOcrSdBeleg(false);
    }

    if (ocrPspElement) {
      setPspElement("");
      setOcrPspElement(false);
    }

    if (ocrArticle) {
      setArticleId("");
      setArticleName("");
      setArticleNumber("");
      setOcrArticle(false);
    }

    if (ocrAmount) {
      setAmount("");
      setOcrAmount(false);
    }

    if (ocrPackagingStage) {
      setPackagingStage("");
      setOcrPackagingStage(false);
    }

    if (ocrVersorgungsNummer) {
      setVersorgungsNummer("");
      setOcrVersorgungsNummer(false);
    }

    setUploadedPDF("");
  };

  const handleFileUpload = (event: any) => {
    setIsFileUploading(true);
    let uploadToast = toast.loading("Datei hochladen...");
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    const token = localStorage.getItem("empToken");
    fetch(
      `/api/orders/${props.order.id}/file-upload`,
      {
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsFileUploading(false);
        toast.success(data.message, {
          id: uploadToast,
        });
        setOrderFiles([data.file, ...orderFiles]);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Hochladen fehlgeschlagen");
      });
  };

  const handleFileDeletion = (file: any) => {
    const token = localStorage.getItem("empToken");
    fetch(
      `/api/orders/${props.order.id}/file-delete/${file.fileKey}`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let files = orderFiles;

        const updatedOrderFiles = files.filter(
          (target: any) => target.fileKey !== file.fileKey
        );

        toast.success("Löschen erfolgreich");

        setOrderFiles(updatedOrderFiles);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Löschen fehlgeschlagen");
      });
  };

  type colliType = {
    id: any;
    lecNumber: any;
    amount: any;
    length: any;
    width: any;
    height: any;
    weightBrutto: any;
    packaging: any;
  };

  const AddColli = () => {
    const addColli = () => {
      let colliId = Date.now();

      let newCollisData: any = [
        ...handlingUnitsData,
        {
          id: colliId,
          lecNumber: null,
          amount: "",
          length: "",
          width: "",
          height: "",
          weightBrutto: 0,
          packaging: "",
        },
      ];

      setHandlingUnitsData(newCollisData);
    };

    return (
      <Button
        className="addElementButton"
        endIcon={<AddCircleOutlineIcon style={{ fontSize: "16px" }} />}
        variant="text"
        onClick={() => addColli()}
        disableRipple
        sx={{
          height: 33,
          width: 170,
          "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#FFF",
          },
          padding: 0,
          alignSelf: "flex-end",
          margin: "1.5% 1% 3% 1% !important",
        }}
      >
        <p className="addElementButtonText">Colli hinzufügen</p>
      </Button>
    );
  };

  const [isOrderMasterDataModalVisible, setIsOrderMasterDataModalVisible] =
    useState(false);
  const [orderMasterDataModalType, setOrderMasterDataModalType] = useState("");

  const closeOrderMasterDataModal = (isVisible: boolean) => {
    if (orderMasterDataModalType === "customers") {
      getCustomers();
    } else if (orderMasterDataModalType === "articles") {
      getArticles();
    } else if (orderMasterDataModalType === "carriers") {
      getCarriers();
    }

    setIsOrderMasterDataModalVisible(isVisible);
    setOrderMasterDataModalType("");
  };

  const AddMasterData = (label: string, type: string) => {
    return (
      <div
        className="addMasterDataButton"
        onClick={() => {
          setOrderMasterDataModalType(type);
          setIsOrderMasterDataModalVisible(true);
        }}
      >
        <p className="addMasterDataButtonText">
          Keine Option... Neuen {label} hinzufügen
        </p>
        <div className="addMasterDataButtonIcon">
          <AddCircleOutlineIcon style={{ fontSize: "16px" }} />
        </div>
      </div>
    );
  };

  const textFieldRef: any = useRef(null);

  const ColliRow = (
    id: any,
    lecNumber: any,
    amount: any,
    length: any,
    width: any,
    height: any,
    weightBrutto: any,
    packaging: any
  ) => {
    const updateColli = (field: any, input: any) => {
      const updatedHandlingUnitsData = handlingUnitsData;

      if (field === "lecNumber") {
        if (lecNumber.length === 0) {
          let colliIndex = updatedHandlingUnitsData.findIndex(
            (colli: colliType) => colli.id === id
          );

          updatedHandlingUnitsData[colliIndex].lecNumber =
            input[input.length - 1];
        } else {
          if (input.length > lecNumber.length) {
            updatedHandlingUnitsData.push({
              id: Date.now(),
              lecNumber: input[input.length - 1],
              amount: amount,
              length: length,
              width: width,
              height: height,
              weightBrutto: weightBrutto,
              packaging: packaging,
            });
          }
        }

        setHandlingUnitsData(updatedHandlingUnitsData);

        let orderWeight = 0;

        for (let i = 0; i < updatedHandlingUnitsData.length; i++) {
          let colliWeight = Number(updatedHandlingUnitsData[i].weightBrutto);

          orderWeight = orderWeight + colliWeight;
        }

        setWeight(orderWeight.toFixed(2));

        return visualizeCurrentCollis(handlingUnitsData);
      }

      // Finde das Objekt mit der entsprechenden "colliId"
      let collisToUpdate: any = [];

      if (lecNumber.length > 0) {
        collisToUpdate = updatedHandlingUnitsData.filter(
          (obj: colliType) =>
            obj.lecNumber !== null &&
            obj.amount === amount &&
            obj.length === length &&
            obj.width === width &&
            obj.height === height &&
            obj.weightBrutto === weightBrutto &&
            obj.packaging === packaging
        );

        for (let i = 0; i < collisToUpdate.length; i++) {
          if (collisToUpdate.length > 0) {
            if (field === "lecNumber") {
            } else if (field === "amount") {
              collisToUpdate[i].amount = input;
            } else if (field === "weight") {
              collisToUpdate[i].weightBrutto = input;
            } else if (field === "length") {
              collisToUpdate[i].length = input;
            } else if (field === "width") {
              collisToUpdate[i].width = input;
            } else if (field === "height") {
              collisToUpdate[i].height = input;
            } else if (field === "packaging") {
              collisToUpdate[i].packaging = input;
            }
          }
        }
      } else {
        collisToUpdate = updatedHandlingUnitsData.find(
          (obj: colliType) => obj.id === id
        );

        if (collisToUpdate) {
          if (field === "lecNumber") {
          } else if (field === "amount") {
            collisToUpdate.amount = input;
          } else if (field === "weight") {
            collisToUpdate.weightBrutto = input;
          } else if (field === "length") {
            collisToUpdate.length = input;
          } else if (field === "width") {
            collisToUpdate.width = input;
          } else if (field === "height") {
            collisToUpdate.height = input;
          } else if (field === "packaging") {
            collisToUpdate.packaging = input;
          }
        }
      }

      setHandlingUnitsData(updatedHandlingUnitsData);

      let orderWeight = 0;

      for (let i = 0; i < updatedHandlingUnitsData.length; i++) {
        let colliWeight = Number(updatedHandlingUnitsData[i].weightBrutto);

        orderWeight = orderWeight + colliWeight;
      }

      setWeight(orderWeight.toFixed(2));

      return visualizeCurrentCollis(handlingUnitsData);
    };

    const handleLecNumberBlur = (event: any, inputValue: any) => {
      const newValue = inputValue.trim();
      if (newValue && !lecNumber.includes(newValue)) {
        const event = new KeyboardEvent("keydown", {
          key: "Enter",
          keyCode: 13,
          code: "Enter",
          which: 13,
          bubbles: true,
        });

        textFieldRef.current.dispatchEvent(event);
      }
    };

    return (
      <div className="colliContainer">
        <div className="colliContainerHeader">
          <p>Colli</p>
          <div className="colliContainerHeaderButton">
            <IconButton onClick={() => removeCollis(lecNumber, id)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="colliContainerRow">
          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            onChange={(event, value) => updateColli("lecNumber", value)}
            freeSolo
            size="small"
            sx={{ width: "100%" }}
            value={lecNumber}
            renderTags={(value: readonly string[], getTagProps: any) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  size="small"
                  {...getTagProps({ index })}
                  onDelete={() => removeColli(index)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="LEC-Nummer(n)"
                size="small"
                placeholder={"Weitere LEC-Nummer"}
                onBlur={(event) =>
                  handleLecNumberBlur(event, params.inputProps.value)
                }
                inputRef={textFieldRef}
              />
            )}
          />
        </div>
        <div className="colliContainerRow">
          {/*<TextField
            id="lecNumber"
            label="LEC-Nummer"
            variant="outlined"
            sx={{ width: "30%" }}
            value={lecNumber}
            onChange={(value) => updateColli("lecNumber", value.target.value)}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />*/}
          <TextField
            id="outlined-basic"
            label="Artikelanzahl"
            variant="outlined"
            value={amount}
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            onChange={(value) => updateColli("amount", value.target.value)}
            sx={{ width: "30%" }}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <TextField
            id="packaging"
            label="Verpackungart"
            variant="outlined"
            sx={{ width: "30%" }}
            value={packaging}
            onChange={(value) => updateColli("packaging", value.target.value)}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <TextField
            id="outlined-basic"
            label="Gewicht"
            variant="outlined"
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            value={weightBrutto}
            defaultValue={0}
            onChange={(value) => updateColli("weight", value.target.value)}
            sx={{ width: "30%" }}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">KG</InputAdornment>,
              sx: { height: 40 },
              inputProps: {
                min: 0,
              },
            }}
          />
        </div>
        <div className="colliContainerRow">
          <TextField
            id="outlined-basic"
            label="Länge"
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            variant="outlined"
            value={length}
            onChange={(value) => updateColli("length", value.target.value)}
            sx={{ width: "30%" }}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
              sx: { height: 40 },
              inputProps: {
                min: 0,
              },
            }}
          />
          <TextField
            id="outlined-basic"
            label="Breite"
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            variant="outlined"
            value={width}
            onChange={(value) => updateColli("width", value.target.value)}
            sx={{ width: "30%" }}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
              sx: { height: 40 },
              inputProps: {
                min: 0,
              },
            }}
          />
          <TextField
            id="outlined-basic"
            label="Höhe"
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            variant="outlined"
            value={height}
            onChange={(value) => updateColli("height", value.target.value)}
            sx={{ width: "30%" }}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
              sx: { height: 40 },
              inputProps: {
                min: 0,
              },
            }}
          />
        </div>
        <div className="colliContainerFooter">
          <p>
            Anzahl gleicher Collis:{" "}
            {lecNumber.length > 0 ? lecNumber.length : 1}
          </p>
        </div>
      </div>
    );
  };

  const visualizeCurrentCollis = (data: any) => {
    let visualColliRows: any = [];
    let visualColliRowsData: any = [];

    for (let i = 0; i < data.length; i++) {
      const orderColliData: colliType = data[i] as colliType;

      let lecNumbersArray: any = [];

      const colliIndex = visualColliRowsData.findIndex(
        (colli: colliType) =>
          colli.amount === orderColliData.amount &&
          colli.length === orderColliData.length &&
          colli.width === orderColliData.width &&
          colli.height === orderColliData.height &&
          colli.weightBrutto === orderColliData.weightBrutto &&
          colli.packaging === orderColliData.packaging
      );

      if (colliIndex > -1 && orderColliData.lecNumber !== null) {
        lecNumbersArray = [
          ...visualColliRowsData[colliIndex].lecNumber,
          orderColliData.lecNumber,
        ];

        visualColliRowsData[colliIndex].lecNumber = lecNumbersArray;
      } else {
        if (orderColliData.lecNumber !== null) {
          lecNumbersArray = [orderColliData.lecNumber];
        }

        visualColliRowsData.push({
          id: orderColliData.id,
          lecNumber: lecNumbersArray,
          amount: orderColliData.amount,
          length: orderColliData.length,
          width: orderColliData.width,
          height: orderColliData.height,
          weightBrutto: orderColliData.weightBrutto,
          packaging: orderColliData.packaging,
        });
      }
    }

    for (let i = 0; i < visualColliRowsData.length; i++) {
      const orderColliData: colliType = visualColliRowsData[i] as colliType;

      visualColliRows.push(
        ColliRow(
          orderColliData.id,
          orderColliData.lecNumber,
          orderColliData.amount,
          orderColliData.length,
          orderColliData.width,
          orderColliData.height,
          orderColliData.weightBrutto,
          orderColliData.packaging
        )
      );
    }

    setHandlingUnits(visualColliRows);
  };

  const removeColli = (colliIndex: any) => {
    let currentHandlingUnits = handlingUnitsData;

    if (colliIndex > -1) {
      currentHandlingUnits.splice(colliIndex, 1);
    }

    visualizeCurrentCollis([]);
    let orderWeight = 0;

    for (let i = 0; i < currentHandlingUnits.length; i++) {
      let colliWeight = Number(currentHandlingUnits[i].weightBrutto);

      orderWeight = orderWeight + colliWeight;
    }

    setWeight(orderWeight.toFixed(2));

    setHandlingUnitsData(currentHandlingUnits);
    Promise.resolve().then(() => visualizeCurrentCollis(currentHandlingUnits));
  };

  const removeCollis = (lecNumbers: any, colliId: any) => {
    let currentHandlingUnits = handlingUnitsData;

    if (lecNumbers.length > 0) {
      for (let i = 0; i < lecNumbers.length; i++) {
        const colliIndex = currentHandlingUnits.findIndex(
          (colli: colliType) => colli.lecNumber === lecNumbers[i]
        );

        if (colliIndex > -1) {
          currentHandlingUnits.splice(colliIndex, 1);
        }
      }
    } else {
      const colliIndex = currentHandlingUnits.findIndex(
        (colli: colliType) => colli.id === colliId
      );

      if (colliIndex > -1) {
        currentHandlingUnits.splice(colliIndex, 1);
      }
    }

    visualizeCurrentCollis([]);

    let orderWeight = 0;

    for (let i = 0; i < currentHandlingUnits.length; i++) {
      let colliWeight = Number(currentHandlingUnits[i].weightBrutto);

      orderWeight = orderWeight + colliWeight;
    }

    setWeight(orderWeight.toFixed(2));

    setHandlingUnitsData(currentHandlingUnits);
    Promise.resolve().then(() => visualizeCurrentCollis(currentHandlingUnits));
  };

  useEffect(() => {
    let orderWeight = 0;

    for (let i = 0; i < handlingUnitsData.length; i++) {
      let colliWeight = Number(handlingUnitsData[i].weightBrutto);

      orderWeight = orderWeight + colliWeight;
    }

    setWeight(orderWeight.toFixed(2));

    visualizeCurrentCollis(handlingUnitsData);
  }, [handlingUnitsData]);

  return (
    <div className="orderModalBackground">
      <div className="orderModalContainer">
        <div className="orderModalHeader">
          <div className="orderModalHeaderLeft">
            <h2>
              {props.order.id === "" ? "Neuer Auftrag" : "Auftrag bearbeiten"}
            </h2>
            <p>
              ID: {props.order.ident === "" ? nextIdent : props.order.ident}
            </p>
          </div>
          <IconButton onClick={() => props.closeModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="orderModalBody">
          <div className="orderModalBodyTop">
            <FormControlLabel
              control={
                <Switch
                  checked={isPriority}
                  onChange={(event) => setIsPriority(event.target.checked)}
                />
              }
              label="Auftrag hat Priorität"
            />
            <div className="orderModalBodyTopRight">
              {uploadedPDF && "Bitte überprüfe die markierten Felder!"}
            </div>
          </div>
          <TextField
            id="outlined-select-orderState"
            select
            label="Auftragsstatus"
            value={orderState}
            onChange={(value) => {
              setOrderState(value.target.value);
            }}
            sx={{ marginBottom: "25px", width: "100%" }}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          >
            {orderStates.map(
              (option: { displayName: string; value: string }) => (
                <MenuItem key={option.displayName} value={option.value}>
                  {option.displayName}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            id="lieferscheinnummer"
            label="Lieferscheinnummer"
            variant="outlined"
            sx={
              ocrLieferscheinnummer
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            value={lieferscheinNummer}
            onChange={(value) => setLieferscheinNummer(value.target.value)}
            required
            error={lieferscheinnummerHelpertext === "" ? false : true}
            helperText={lieferscheinnummerHelpertext}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Auftragsdatum"
              value={orderDate}
              onChange={(value) => {
                setOrderDate(value);
              }}
              format={"DD.MM.YYYY"}
              sx={
                ocrOrderDate
                  ? ocrInputStyles
                  : {
                      marginBottom: "25px",
                      width: "100%",
                    }
              }
              slotProps={{
                textField: {
                  helperText: orderDateHelpertext,
                  error: orderDateHelpertext === "" ? false : true,
                  required: true,
                  size: "small",
                  InputProps: {
                    sx: { height: 40 },
                  },
                },
              }}
              /*renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: "25px", width: "100%" }}
                  required
                  error={orderDateHelpertext === "" ? false : true}
                  helperText={orderDateHelpertext}
                />
              )}*/
            />
          </LocalizationProvider>
          <Autocomplete
            disablePortal
            id="outlined-basic"
            options={customers.map((option: { name: string }) => option.name)}
            onChange={(event, newName) => {
              const selectedItem: any = customers.find(
                (item: any) => item.name === newName
              );
              if (selectedItem) {
                setSenderId(selectedItem.id);
                setSenderName(selectedItem.name);
              } else {
                setSenderId("");
                setSenderName("");
              }
            }}
            disabled={isOrderMasterDataModalVisible}
            value={senderName}
            noOptionsText={[AddMasterData("Absender", "customers")]}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-select-sender"
                label="Absender"
                //onFocus={() => getCustomers()}
                sx={
                  ocrSender
                    ? ocrInputStyles
                    : {
                        marginBottom: "25px",
                        width: "100%",
                      }
                }
                required
                error={senderHelpertext === "" ? false : true}
                helperText={senderHelpertext}
                size="small"
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="outlined-basic"
            options={customers.map((option: { name: string }) => option.name)}
            onChange={(event, newName) => {
              const selectedItem: any = customers.find(
                (item: any) => item.name === newName
              );
              if (selectedItem) {
                setRecipientId(selectedItem.id);
                setRecipientName(selectedItem.name);
              } else {
                setRecipientId("");
                setRecipientName("");
              }
            }}
            disabled={isOrderMasterDataModalVisible}
            value={recipientName}
            noOptionsText={[AddMasterData("Empfänger", "customers")]}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-select-recipient"
                label="Empfänger"
                //onFocus={() => getCustomers()}
                sx={
                  ocrRecipient
                    ? ocrInputStyles
                    : {
                        marginBottom: "25px",
                        width: "100%",
                      }
                }
                required
                error={recipientHelpertext === "" ? false : true}
                helperText={recipientHelpertext}
                size="small"
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="outlined-basic"
            options={carriers.map((option: { name: string }) => option.name)}
            onChange={(event, newName) => {
              const selectedItem: any = carriers.find(
                (item: any) => item.name === newName
              );
              if (selectedItem) {
                setCarrierId(selectedItem.id);
                setCarrierName(selectedItem.name);
              } else {
                setCarrierId("");
                setCarrierName("");
              }
            }}
            disabled={isOrderMasterDataModalVisible}
            value={carrierName}
            noOptionsText={[AddMasterData("Spediteur", "carriers")]}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-select-carrier"
                label="Spedition"
                //onFocus={() => getCarriers()}
                sx={
                  ocrCarrier
                    ? ocrInputStyles
                    : {
                        marginBottom: "25px",
                        width: "100%",
                      }
                }
                size="small"
              />
            )}
          />
          <TextField
            id="outlined-basic"
            label="SD-Beleg"
            variant="outlined"
            value={sdBeleg}
            onChange={(value) => setSdBeleg(value.target.value)}
            sx={
              ocrSdBeleg
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            error={sdBelegHelpertext === "" ? false : true}
            helperText={sdBelegHelpertext}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <TextField
            id="outlined-basic"
            label="PSP-Element"
            variant="outlined"
            value={pspElement}
            onChange={(value) => setPspElement(value.target.value)}
            sx={
              ocrPspElement
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <Autocomplete
            disablePortal
            id="outlined-basic"
            options={articles.map(
              (option: { articleNumber: string }) => option.articleNumber
            )}
            onChange={(event, newNumber) => {
              const selectedItem: any = articles.find(
                (article: any) => article.articleNumber === newNumber
              );
              if (selectedItem) {
                setArticleId(selectedItem.id);
                setArticleName(selectedItem.name);
                setArticleNumber(selectedItem.articleNumber);
              } else {
                setArticleId("");
                setArticleName("");
                setArticleNumber("");
              }
            }}
            disabled={isOrderMasterDataModalVisible}
            value={articleNumber}
            noOptionsText={[AddMasterData("Artikel", "articles")]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Artikelnummer"
                id="outlined-select-articlenumber"
                defaultValue=""
                sx={
                  ocrArticle
                    ? ocrInputStyles
                    : {
                        marginBottom: "25px",
                        width: "100%",
                      }
                }
                required
                error={articleHelpertext === "" ? false : true}
                helperText={articleHelpertext}
                size="small"
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="outlined-basic"
            options={articles.map((option: { name: string }) => option.name)}
            onChange={(event, newName) => {
              const selectedItem: any = articles.find(
                (article: any) => article.name === newName
              );
              if (selectedItem) {
                setArticleId(selectedItem.id);
                setArticleName(selectedItem.name);
                setArticleNumber(selectedItem.articleNumber);
              } else {
                setArticleId("");
                setArticleName("");
                setArticleNumber("");
              }
            }}
            disabled={isOrderMasterDataModalVisible}
            value={articleName}
            noOptionsText={[AddMasterData("Artikel", "articles")]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Artikel"
                id="outlined-select-article"
                defaultValue=""
                sx={
                  ocrArticle
                    ? ocrInputStyles
                    : {
                        marginBottom: "25px",
                        width: "100%",
                      }
                }
                required
                error={articleHelpertext === "" ? false : true}
                helperText={articleHelpertext}
                size="small"
              />
            )}
          />
          <TextField
            id="outlined-basic"
            label="Artikelanzahl"
            variant="outlined"
            value={amount}
            type={"number"}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            onChange={(value) => setAmount(value.target.value)}
            sx={
              ocrAmount
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            required
            error={amountHelpertext === "" ? false : true}
            helperText={amountHelpertext}
            size="small"
            InputProps={{
              sx: { height: 40 },
              inputProps: { min: 0, max: 1000000 },
            }}
          />
          <TextField
            id="outlined-basic"
            label="Gewicht"
            variant="outlined"
            value={weight}
            sx={{ marginBottom: "25px", width: "100%" }}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end">KG</InputAdornment>,
              sx: { height: 40 },
            }}
          />
          <TextField
            id="outlined-basic"
            label="Collianzahl"
            variant="outlined"
            value={handlingUnitsData.length}
            sx={{ /*marginBottom: "25px",*/ width: "100%" }}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          {handlingUnits}
          <AddColli />
          <TextField
            id="outlined-select-packagingstage"
            select
            label="Verpackungsstufe"
            defaultValue="H"
            value={packagingStage}
            onChange={(value) => setPackagingStage(value.target.value)}
            sx={
              ocrPackagingStage
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          >
            {packagingStages.map(
              (option: { value: string; displayName: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.displayName}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            id="outlined-select-specifics"
            select
            label="Besonderheiten"
            defaultValue="ADR"
            value={specifics}
            onChange={(value) => setSpecifics(value.target.value)}
            sx={{ marginBottom: "25px", width: "100%" }}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          >
            {specificsOptions.map(
              (option: { value: string; displayName: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.displayName}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            id="outlined-basic"
            label="Versorgungsnummer"
            variant="outlined"
            value={versorgungsNummer}
            onChange={(value) => setVersorgungsNummer(value.target.value)}
            sx={
              ocrVersorgungsNummer
                ? ocrInputStyles
                : {
                    marginBottom: "25px",
                    width: "100%",
                  }
            }
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Auftrag Abschluss"
              value={finishDate}
              onChange={(value) => {
                setFinishDate(value);
              }}
              format={"DD.MM.YYYY"}
              sx={{ marginBottom: "25px", width: "100%" }}
              slotProps={{
                textField: {
                  helperText: finishDateHelpertext,
                  error: finishDateHelpertext === "" ? false : true,
                  size: "small",
                  InputProps: {
                    sx: { height: 40 },
                  },
                },
              }}
              /*renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: "25px", width: "100%" }}
                />
              )}*/
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Abgeholt am"
              value={pickUpDate}
              onChange={(value) => {
                setPickUpDate(value);
              }}
              format={"DD.MM.YYYY"}
              sx={{ marginBottom: "25px", width: "100%" }}
              slotProps={{
                textField: {
                  helperText: pickUpDateHelpertext,
                  error: pickUpDateHelpertext === "" ? false : true,
                  size: "small",
                  InputProps: {
                    sx: { height: 40 },
                  },
                },
              }}
              /*renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ marginBottom: "25px", width: "100%" }}
                />
              )}*/
            />
          </LocalizationProvider>
          <TextField
            id="outlined-basic"
            label="ID-Warenausgangsbuchung"
            variant="outlined"
            value={idGoodsIssued}
            onChange={(value) => setIdGoodsIssued(value.target.value)}
            sx={{ marginBottom: "0px", width: "100%" }}
            size="small"
            InputProps={{
              sx: { height: 40 },
            }}
          />
          <div className="orderAttachmentContainer">
            <div className="orderAttachmentContainerHeader">
              <h3 className="orderAttachmentContainerHeaderText">
                Angehängte Dateien
              </h3>
              <Button
                component="label"
                className="addElementButton"
                endIcon={
                  !isFileUploading ? (
                    <UploadFileIcon />
                  ) : (
                    <CircularProgress size={16} />
                  )
                }
                variant="text"
                sx={{
                  height: 33,
                  width: "auto",
                  "&:hover": {
                    backgroundColor: "#FFF",
                  },
                  padding: 0,
                  alignSelf: "center",
                  fontSize: "16px !important",
                  marginRight: "1% !important",
                }}
                disabled={isOcrScanning || isFileUploading || !props.order.id}
              >
                <input type="file" hidden onChange={handleFileUpload} />
                {isFileUploading ? (
                  <p>Datei hochladen...</p>
                ) : (
                  <p>Datei anhängen</p>
                )}
              </Button>
            </div>
            {orderFiles.length > 0 ? (
              <div className="orderAttachmentsList">
                {orderFiles.map((file: any) => (
                  <div className="orderModalFile">
                    <a
                      className="orderModalFileLabel"
                      href={`/api/orders/${props.order.id}/file-download/${file.fileKey}`}
                      download={file.fileName}
                    >
                      {file.fileName}
                    </a>
                    <div
                      className="orderModalFileIcon"
                      onClick={() => handleFileDeletion(file)}
                    >
                      <CloseIcon sx={{ fontSize: 22 }} />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {props.order.ident === "" ? (
                  <div className="noAttachments">
                    Dateien können erst nach Auftragserstellung angehängt
                    werden.
                  </div>
                ) : (
                  <div className="noAttachments">Keine Anhänge</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="orderModalFooter">
          <div className="orderModalFooterLeft">
            <Button
              className="orderModalFooterButton"
              variant="contained"
              //startIcon={<SaveIcon />}
              sx={{ marginRight: "7%", height: 33, width: 116 }}
              onClick={
                props.order.ident === ""
                  ? () => saveOrder()
                  : () => updateOrder()
              }
            >
              {props.order.ident === "" ? "Speichern" : "Speichern"}
            </Button>
            <Button
              className="orderModalFooterButton"
              variant="outlined"
              //startIcon={<CloseIcon />}
              sx={{ height: 33, width: 103 }}
              onClick={() => {
                props.closeModal(false);
                toast.dismiss();
              }}
            >
              Abbruch
            </Button>
          </div>
          <div className="orderModalFooterRight">
            {uploadedPDF === "" ? (
              <Button
                component="label"
                className="orderModalFooterButton"
                variant="outlined"
                //startIcon={<CloseIcon />}
                sx={{ height: 33, width: 120 }}
                disabled={isOcrScanning || isFileUploading}
              >
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  onChange={handleOCRFileUpload}
                />
                {isOcrScanning ? (
                  <CircularProgress size={20} />
                ) : (
                  <div>OCR-Scan</div>
                )}
              </Button>
            ) : (
              <div className="orderModalOCRFile">
                <div className="orderModalOCRFileLabel">{uploadedPDF}</div>
                <div
                  className="orderModalOCRFileIcon"
                  onClick={() => removeUploadedOCRPdf()}
                >
                  <CloseIcon sx={{ fontSize: 22 }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster />
      {isOrderMasterDataModalVisible && (
        <MasterDataModal
          closeModal={closeOrderMasterDataModal}
          scene={orderMasterDataModalType}
          item={{ id: "", ident: "", name: "" }}
          setItem={{}}
          getItems={() => {}}
        />
      )}
    </div>
  );
};

export default OrderModal;
