import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
//import logo from "../../Lechner_Logo.png";
import logo from "../../EMP_Logov2.png";

// Styles
import "./dashboard.css";

// Icons
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
//import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

// Boards
import Orders from "../../boards/orders/orders";
import MasterData from "../../boards/masterData/masterData";

// Custom Components
import FeedbackModal from "../../components/feedbackModal/feedbackModal";
import SupportModal from "../../components/supportModal/supportModal";

const Dashboard = () => {
  const [activeScene, setActiveScene] = useState(
    window.location.pathname === "/" ||
      window.location.pathname === "/orders" ||
      window.location.pathname === "/open-orders"
      ? "openOrders"
      : window.location.pathname === "/finished-orders"
      ? "finishedOrders"
      : window.location.pathname === "/customers"
      ? "customers"
      : window.location.pathname === "/articles"
      ? "articles"
      : window.location.pathname === "/addresses"
      ? "addresses"
      : "carriers"
  );
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  {
    /*const [isNavDataExpanded, setIsNavDataExpanded] = useState(
    window.location.pathname === "/customers" ||
      window.location.pathname === "/articles" ||
      window.location.pathname === "/addresses" ||
      window.location.pathname === "/carriers"
      ? true
      : false
  );*/
  }

  const [isNavMasterDataExpanded, setIsNavMasterDataExpanded] = useState(true);
  const [isNavOrdersExpanded, setIsNavOrdersExpanded] = useState(true);

  const [componentKey, setComponentKey] = useState(0);

  const [user, setUser] = useState<any>({
    id: "",
    name: "",
    email: "",
    createdAt: "",
    updatedAt: "",
    roleId: "",
    role: {
      id: "",
      name: "",
      permissions: "",
      createdAt: "",
      updatedAt: "",
    },
  });

  const getUser = () => {
    const token = localStorage.getItem("empToken");
    const userId = localStorage.getItem("empUserId");

    fetch(`/api/users/${userId}`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.token) {
          if (
            data.token === "Token expired" ||
            data.token === "Token invalid"
          ) {
            localStorage.removeItem("empToken");
            localStorage.removeItem("empUserId");
            window.location.href = "/";
          }
        }

        setUser(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getUser();
  }, []);

  const logout = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/auth/logout`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .catch((err) => console.error(err));
    localStorage.removeItem("empToken");
    localStorage.removeItem("empUserId");
    window.location.href = "/";
  };

  return (
    <BrowserRouter>
      <div className="dashboardContainer">
        <div className="sideBar">
          <div className="sideBarHeader">
            <img src={logo} alt="logo" />
            <p>EMP</p>
            <p
              style={{
                color: "#666666",
                fontSize: "25px",
                paddingTop: "6%",
                marginLeft: "1%",
              }}
            >
              ONE
            </p>
          </div>
          <div className="sideBarBody">
            <div className="sideBarBodyTop">
              <div className="sideBarBodySubtitle">
                <p>Allgemein</p>
              </div>
              <div
                className="sideBarNavRowNotActive"
                onClick={() =>
                  setIsNavOrdersExpanded(isNavOrdersExpanded ? false : true)
                }
              >
                <div className="sideBarNavRowIcon">
                  <ViewInArRoundedIcon />
                </div>
                <div className="sideBarNavRowText">
                  <p>Aufträge</p>
                </div>
                <div className="sideBarNavRowExpand">
                  {!isNavOrdersExpanded ? (
                    <ExpandMoreRoundedIcon />
                  ) : (
                    <ExpandLessRoundedIcon />
                  )}
                </div>
              </div>
              {isNavOrdersExpanded && (
                <div className="sideBarNavExpandedContainer">
                  <Link
                    className={
                      activeScene === "openOrders"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => {
                      setActiveScene("openOrders");
                      setComponentKey(componentKey + 1);
                    }}
                    to="/open-orders"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Offen</p>
                    </div>
                  </Link>
                  <Link
                    className={
                      activeScene === "finishedOrders"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => {
                      setActiveScene("finishedOrders");
                      setComponentKey(componentKey + 1);
                    }}
                    to="/finished-orders"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Abgeschlossen</p>
                    </div>
                  </Link>
                </div>
              )}
              <div
                className="sideBarNavRowNotActive"
                onClick={() =>
                  setIsNavMasterDataExpanded(
                    isNavMasterDataExpanded ? false : true
                  )
                }
              >
                <div className="sideBarNavRowIcon">
                  <InsertChartOutlinedRoundedIcon />
                </div>
                <div className="sideBarNavRowText">
                  <p>Stammdaten</p>
                </div>
                <div className="sideBarNavRowExpand">
                  {!isNavMasterDataExpanded ? (
                    <ExpandMoreRoundedIcon />
                  ) : (
                    <ExpandLessRoundedIcon />
                  )}
                </div>
              </div>
              {isNavMasterDataExpanded && (
                <div className="sideBarNavExpandedContainer">
                  <Link
                    className={
                      activeScene === "customers"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => {
                      setActiveScene("customers");
                      setComponentKey(componentKey + 1);
                    }}
                    to="/customers"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Adressbuch</p>
                    </div>
                  </Link>
                  <Link
                    className={
                      activeScene === "articles"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => {
                      setActiveScene("articles");
                      setComponentKey(componentKey + 1);
                    }}
                    to="/articles"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Artikel</p>
                    </div>
                  </Link>
                  {/*<Link
                    className={
                      activeScene === "addresses"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => setActiveScene("addresses")}
                    to="/addresses"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Adressen</p>
                    </div>
                  </Link>*/}
                  <Link
                    className={
                      activeScene === "carriers"
                        ? "sideBarNavExpandedRowActive"
                        : "sideBarNavExpandedRowNotActive"
                    }
                    onClick={() => {
                      setActiveScene("carriers");
                      setComponentKey(componentKey + 1);
                    }}
                    to="/carriers"
                  >
                    <div className="sideBarNavExpandedRowText">
                      <p>Speditionen</p>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div className="sideBarBodyBottom">
              <div className="sideBarBodySubtitle">
                <p>Support</p>
              </div>
              <div
                className="sideBarNavRowNotActive"
                onClick={() => setIsSupportModalVisible(true)}
              >
                <div className="sideBarNavRowIcon">
                  <HelpOutlineRoundedIcon />
                </div>
                <div className="sideBarNavRowText">
                  <p>Hilfe</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sideBarFooter">
            <div className="sideBarFooterLogOut" onClick={() => logout()}>
              <div className="sideBarFooterLogOutIcon">
                <LogoutIcon />
              </div>
              <div className="sideBarFooterLogOutText">
                <p>Abmelden</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer">
          <div className="mainContainerHeader">
            <div className="mainContainerHeaderTitle">
              {activeScene === "openOrders" ? (
                <h3>Offene Aufträge</h3>
              ) : activeScene === "finishedOrders" ? (
                <h3>Abgeschlossene Aufträge</h3>
              ) : activeScene === "customers" ? (
                <h3>Adressbuch</h3>
              ) : activeScene === "articles" ? (
                <h3>Artikel</h3>
              ) : /*) : activeScene === "addresses" ? (
                <h3>Adressen</h3>*/
              activeScene === "carriers" ? (
                <h3>Speditionen</h3>
              ) : (
                <h3>Offene Aufträge</h3>
              )}
            </div>
            <div className="mainContainerHeaderProfile">
              <div className="mainContainerHeaderProfileText">
                <p>{user.name}</p>
              </div>
              <div className="mainContainerHeaderProfileIcon">
                {user.name !== "" && <AccountCircleIcon />}
              </div>
            </div>
          </div>
          <div className="mainContainerBody">
            <Routes>
              <Route
                path="/"
                element={
                  <Orders user={user} scene="openOrders" key={componentKey} />
                }
              ></Route>
              <Route
                path="/open-orders"
                element={
                  <Orders user={user} scene="openOrders" key={componentKey} />
                }
              ></Route>
              <Route
                path="/finished-orders"
                element={
                  <Orders
                    user={user}
                    scene="finishedOrders"
                    key={componentKey}
                  />
                }
              ></Route>
              <Route
                path="/customers"
                element={
                  <MasterData
                    user={user}
                    scene="customers"
                    key={componentKey}
                  />
                }
              ></Route>
              <Route
                path="/articles"
                element={
                  <MasterData user={user} scene="articles" key={componentKey} />
                }
              ></Route>
              {/*<Route
                path="/addresses"
                element={<MasterData user={user} scene="addresses" />}
              ></Route>*/}
              <Route
                path="/carriers"
                element={
                  <MasterData user={user} scene="carriers" key={componentKey} />
                }
              ></Route>
            </Routes>
          </div>
        </div>
        {isSupportModalVisible && (
          <SupportModal closeModal={setIsSupportModalVisible} />
        )}
        {isFeedbackModalVisible && (
          <FeedbackModal closeModal={setIsFeedbackModalVisible} />
        )}
      </div>
    </BrowserRouter>
  );
};

export default Dashboard;
