import React from "react";

// MUI Components
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

// Styles
import "./feedbackModal.css";

// Icons
import CloseIcon from "@mui/icons-material/Close";

const FeedbackModal = (props: any) => {
  return (
    <div className="feedbackModalBackground">
      <div className="feedbackModalContainer">
        <div className="feedbackModalHeader">
          <div className="feedbackModalHeaderLeft">
            <h2>Feedback</h2>
          </div>
          <div className="feedbackModalHeaderRight">
            <IconButton onClick={() => props.closeModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="feedbackModalBody"></div>
      </div>
    </div>
  );
};

export default FeedbackModal;
